$maxMobile: 767px;
$mintablet: maxPhone + 1; 
$maxTablet: 1024px;

@mixin media($device) {
    @if $device == "mobile" {
        @media all and (max-width: $maxMobile) {
            @content;
        }
    } @else if $device == "tablet" {
        @media all and (max-width: $maxTablet) {
            @content;
        }
    }
}


