/* invite container in Project/board page */
#invite-container {
    position: absolute;
    padding: 3rem 3rem 2rem 2rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--grey4);
    border-radius: var(--radius);
    background-color: var(--white);
    z-index: 50;
    max-width: 33em;
    max-height: 85vh;
	box-shadow: var(--box-shadow);
    h3 {
        margin: 0 0 1em 0;
    }
    #user-with-access, #access-requests {
        margin-top: 1.5em;
        .title-area{
            display: flex;
            margin: 1em 0;
            h3 {
                font-size: var(--font-size-default);
                margin: 0;
            }
            > div {
                margin-left: 1em;
            }
        }
        .user-list {
            margin: 0;
            padding: 0;
            list-style: none;
            >li {
                display:grid;
                grid-template-columns: 39px 1fr 8em;
                column-gap: .5em;
                margin-bottom: 1em;
                .user-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        margin:0;
                    }
                    :first-child {
                        font-size:var(--font-size-small);
                        font-weight:600;
                    }
                    :last-child {
                        font-size:.8em;
                        color:var(--grey4)
                    }
                }
                .user-role {
                    display: flex;
                    align-items: center;
                    font-size: var(--font-size-small);
                }
                .menu-outer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .user-menu {
                        position: relative;
                        .menu-list {
                            bottom: -2.5em;
                            left: 1.8em;
                            li {
                                margin: 0;
                                button {
                                    display: inline-block;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .decision-buttons {
                    display: flex;
                    justify-content: flex-end;
                    button {
                        font-size: 12px;
                        border: 1px solid #7b7b7b;
                        padding: 0.1em 1em;
                        border-radius: 0.5em;
                        cursor: pointer;
                        background-color: var(--white);
                        color: #7b7b7b;
                        margin: 0 0.2em;
                        &.disabled {
                            color: var(--grey5);
                            border: 1px solid var(--grey5);
                            cursor: auto;
                            background-color: var(--grey4-menu);
                        }
                        &.decline {
                            border-color: #800;
                            color: #800;
                            &:hover {
                                background-color: #800;
                                color: var(--white);
                                border: 1px solid #800;
                            }
                        }
                        &.accept {
                            border-color: #080;
                            color: #080;
                            &:hover {
                                background-color: #080;
                                color: var(--white);
                                border: 1px solid #080;
                            }
                        }
                    }
                }
            }
        }
    }
    .invite-result {
        font-size: var(--font-size-default);
        line-height: 18.75px;
        color: var(--grey3);
    }
    .ui-widget {
        padding: 0.5rem 0;
        min-width: 26.625rem;
    }
    label {
        display:none;
    }
    .userNameInput {
        border: none;
        border-bottom: 1px solid var(--grey4);
        position: relative;
        color: var(--grey6);
        margin: 0;
        width: 100%;
        .input-outer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            input[type='text'] {
                width: calc(100% - 100px);
                outline: none;
                border: none;
                padding: 0.8rem 0.625rem;
                font-size: 1em;
                &::placeholder {
                    color: #767680;
                }
                &::-webkit-input-placeholder {
                    color: #767680;
                }
                &::-ms-input-placeholder {
                    color: #767680;
                }
            }
            input:autofill {
                box-shadow: 0 0 0 1000px white inset;
            }
            button {
                display: block;
                height: 38px;
                min-width: 110px;
                border: 1px solid #7b7b7b;
                padding: 0 1em;
                border-radius: 10px;
                cursor: pointer;
                background-color: var(--white);
                color: #7b7b7b;
                margin: 0;
                :hover {
                    background-color: var(--blue);
                    color: var(--white);
                    border: 1px solid var(--grey7);
                }
            }
        }
    }
    .access-control-outer {
      padding-top: 1em;
        h4 {
            font-size: var(--font-size-default);
            margin: 1.5em 0 1em;
        }
        .access-status {
            display: flex;
            align-items: baseline;
            select{
                background-color: var(--white);
                padding: .5em;
                font-size: var(--font-size-medium);
                border: 1px solid var(--grey4);
                border-radius: var(--radius);
            }
            p {
                font-size: .8em;
                color: var(--grey4);
                margin: 0 0 0 1em;
            }
        }
    }
    .name-select {
        margin: 0.5em;
    }
    button.closeButton {
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        padding: 0;
        border: none;
        background-color: var(--white);
        cursor: pointer;
        right: 1.4375rem;
        top: 1.875rem;
    }
    #autocomplete-results {
        padding: 0;
        margin: 0;
        list-style: none;
        position: absolute;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid var(--grey4);
        border-bottom-left-radius: var(--radius);
        border-bottom-right-radius: var(--radius);
        z-index: 2;

        .general-info {
            padding: 1em 2em 0.5em 2em;
            font-size: .8em;

            h4 {
                color: var(--grey3);
                margin: 0;
            }
            p {
                margin: 0;
            }
        }

        .name-select {
            margin: 0;
            padding: .5em;
            &:hover {
                background-color: var(--grey7);
                cursor: pointer;
            }
            a {
                display: grid;
                grid-template-columns: 39px 1fr;
                column-gap: .5em;
                * {
                    margin: 0;
                }
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    h4 {
                        color: var(--black);
                        font-size: .8em;
                        font-weight: normal;
                    }
                    p {
                        font-size: .8em;
                        color: var(--grey4);
                    }
                }
            }
            .grey {
                background-color: var(--grey4);
            }
        }

    }
    .boxShadow {
        box-shadow: 1px 1px 5px 0 #999;
    }
    button.closeButton img {
        display: block;
        width: inherit;
    }
    .inviteButtons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;
        button {
            border: 1px solid #7b7b7b;
            padding: 0.6em 1em;
            border-radius: 10px;
            cursor: pointer;
            background-color: var(--white);
            color: #7b7b7b;
            margin: 0 0.5625rem;
            &:hover {
                background-color: var(--blue);
                color: var(--white);
                border: 1px solid var(--grey7);
            }
            &.disabled {
                color: var(--grey5);
                border: 1px solid var(--grey5);
                cursor: auto;
                background-color: var(--grey4-menu);
            }
        }
    }
    .invite-result {
        padding-top: 1rem;
        font-weight: 700;
        color: var(--grey2);
        max-width: 30em;
        &.success {
            color: var(--success);
        }
        &.warning {
            color: var(--warning);
        }
        &.copied {
            color: var(--grey3);
        }
        &.copied p {
            margin: 0;
            span {
                display: block;
                font-size: var(--font-size-small);
                color: #7b7b7b;
                padding-top: 0.3rem;
            }
        }
    }
}

@media all and (max-width: 1024px) {
    #invite-container {
        padding: 1.5rem 2rem 0.5rem 1.5rem;

        .access-control-outer {
            padding-top: 0.5em;
        }
    }
}
