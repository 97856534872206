/* Upload Container Styling */
#upload-container {
	position: absolute;
	padding: 3rem 3rem 2rem 2rem;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid var(--grey4);
	border-radius: var(--radius);
	background-color: var(--white);
	z-index: 50;
	max-width: 33em;
	max-height: 85vh;
	box-shadow: var(--box-shadow);

	h3 {
		margin-top: 0px;
		margin-bottom: 1em;
	}

	.ui-widget {
		padding: 0.5rem 0;
		min-width: 26.625rem;
	}

	label {
		display: none;
	}

	/* Styles for the file list */
	.file-list {
		list-style-type: none;
		padding: 0;
		margin: 0;
		max-height: 200px;
		overflow-y: auto;
		border-radius: 5px;
	}

	.file-item {
		display: flex;
		flex-direction: column;
		padding: 10px;
		margin-bottom: 10px;
		background-color: #f5f5f5;
		border-radius: 5px;
		cursor: pointer;
		transition: background-color 0.3s ease;

		&:hover {
			background-color: #e0e0e0;
		}

		.file-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}

		.file-name {
			width: 200px;
			font-weight: bold;
			color: #333;
			flex-grow: 1;
			margin-right: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.uploaded-by {
			font-size: 0.8em;
			color: #777;
		}

		.selected-file-text {
			font-weight: bold;
			font-size: 11px;
			margin-bottom: 5px;
			color: var(--blue);
		}

		&.default {
			border: 1px solid var(--blue);
		}
	}
}
