.welcomeTutorial {
    width:300px;
}
.likeTutorial {
    max-width: none !important;
    width: 400px;
    .heartedListIcon {
        display: inline-block;
        width: 20px;
        height: 24px;
        background-image: url('/assets/icon/board/note.png'), url('/assets/icon/board/heart.png');
        background-size: 90%, 8px;
        background-repeat: no-repeat;
        background-position: 0 2px, 13px 11px;
        vertical-align: bottom;
    }
}
.deleteTutorial .deleteIcon {
    display: inline-block;
    width: 15px;
    height: 20px;
    background-image: url('/assets/graph/delete.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;
}
.synthesiseTutorial .shareNotePadIcon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('/assets/icon/board/shareNoteButton.png');
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;
}
/*------------------------
override introjs to allow clickthrough while class is set on body
--------------------------*/
.enable-tutorial-clickthrough {
    .introjs-helperLayer, .introjs-overlay {
        pointer-events: none;
    }
}

.introjs-tooltip {
    min-width: 300px !important;
}

.video-embed {
    margin-left: -250px !important;
    max-width: none !important;
}
.wide-tooltip {
    margin-left: -300px !important;
    max-width: none !important;
    width: 600px;
}
.medium-tooltip {
    max-width: none !important;
    width: 400px;
}
.medium-wide-tooltip {
    max-width: none !important;
    width: 500px;
}
.introjs-tooltiptext {
    max-height: 400px;
    overflow-y: scroll;

    .embed {
        padding: 0;
        margin: 0.5em 0;
        text-align: center;

        iframe {
            max-width: 420px;
            max-height: 236px;
        }
    }
}

@media all and (max-width : 1024px) {
    .introjs-tooltip-header {
        padding: 8px 15px 0 15px !important;
        .introjs-tooltip-title {
            font-size: 16px !important;
        }
    }

    .introjs-tooltiptext {
        padding: 5px 15px 0 15px !important;
        font-size: 15px !important;
        max-height: 200px;
        overflow-y: scroll;

        .embed {
            iframe {
                max-width: 280px;
                max-height: 158px;
            }
        }
    }
    .introjs-bullets {
        padding: 0 0 5px 0 !important;
    }

    .introjs-tooltipbuttons {
        padding: 5px !important;

        .introjs-button {
            padding: 0.3rem 1rem !important;
        }
    }
}
